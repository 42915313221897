import React from "react";
import { NavLink } from "react-router-dom";

var bnr1 = require("./../../images/background/bg-5.png");

class Specialization extends React.Component {
  render() {
    return (
      <>
        <div
          className="section-full mobile-page-padding bg-white p-t80 p-b30 bg-repeat square_shape1"
          style={{ backgroundImage: "url(" + bnr1 + ")" }}
        >
          <div className="container">
            {/* IMAGE CAROUSEL START */}
            <div className="section-content">
              <div className="row">
                <div className="col-lg-3 col-md-4 col-sm-6 m-b30">
                  <div className="image-effect-one hover-shadow">
                    <img src={require("../../images/pic1.jpg")} alt="" />
                    <div className="figcaption">
                      <h4>Shipping & Freight Forwarding</h4>
                      <p>Reliable Global Shipping and Logistics</p>
                      <NavLink to="/project-detail">
                        <i className="link-plus bg-primary" />
                      </NavLink>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4  col-sm-6 m-b30">
                  <div className="image-effect-one hover-shadow">
                    <img src={require("../../images/pic2.png")} alt="" />
                    <div className="figcaption">
                      <h4>Air Freight</h4>
                      <p>Efficient Air Freight Solutions Worldwide</p>
                      <NavLink to="/project-detail">
                        <i className="link-plus bg-primary" />
                      </NavLink>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4  col-sm-6 m-b30">
                  <div className="image-effect-one hover-shadow">
                    <img src={require("../../images/pic3.jpg")} alt="" />
                    <div className="figcaption bg-dark">
                     <h4>3PL Warehousing</h4>
                      <p>Efficient Storage and Distribution Solutions</p>
                      <NavLink to="/project-detail">
                        <i className="link-plus bg-primary" />
                      </NavLink>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-12 col-sm-6 m-b30">
                  <div className="mt-box our-speciallization-content">
                    <h3
                      className="m-t0"
                      style={{
                        fontSize: 25,
                      }}
                    >
                      Sailing toward success in logistics
                    </h3>
                    <p>
                        When it comes to shipping, trust TimeGlobal shipping experts for seamless solutions
                    </p>
                    <NavLink
                      to="/project-detail"
                      className="site-button btn-effect"
                    >
                      View All
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="hilite-title text-right p-r50 text-uppercase text-pop-up-top">
            <strong>Welcome</strong>
          </div>
        </div>
      </>
    );
  }
}

export default Specialization;
