import React from "react";

const WhatsappChat = () => {
  return (
    <a   href="https://wa.me/971565457842?text=hii" rel="noreferrer" target="_blank" className="whatsappchat">
      <i className="fa fa-whatsapp"></i>
    </a>
  );
};

export default WhatsappChat;
